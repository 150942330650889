// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-news-js": () => import("./../src/pages/about-news.js" /* webpackChunkName: "component---src-pages-about-news-js" */),
  "component---src-pages-black-and-white-studies-js": () => import("./../src/pages/black-and-white-studies.js" /* webpackChunkName: "component---src-pages-black-and-white-studies-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-gallery-js": () => import("./../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */)
}

